import React, {useEffect, useState} from 'react';
import {useGetAllQuery} from "../../../../hooks/api";
import {KEYS} from "../../../../constants/keys";
import {URLS} from "../../../../constants/urls";
import {filter as filterArray, get, includes, isArray, isEmpty, isEqual, uniq, head, find, sumBy} from "lodash";
import {Link, useSearchParams,useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import Title from "../../../../components/title";
import Checkbox from "../../../../components/checkbox";
import ChartDatepicker from "../chart-datepicker";
import {BarChart} from "../../../../components/chart";
import {calculateDashboardChartData} from "../../../../utils";
import Section from "../../../../components/section";
import {useTranslation} from "react-i18next";
import Select from "../../../../components/select";

const Index = ({regionList = {}}) => {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [organization, setOrganization] = useState(null)
    const [dispensary, setDispensary] = useState([])
    const [month, setMonth] = useState(searchParams.get('month') ?? dayjs().subtract(1,'month').format('YYYYMM'))
    const [regionId, setRegionId] = useState(null);

    const {data: organizationList, isLoading: isLoadingOrganizationList} = useGetAllQuery({
        key: KEYS.cities,
        url: `${URLS.dashboardReports}/${month}/states/${get(regionId, 'value')}/organizations`,
        params: {
            params: {
                per_page: 1000
            }
        },
        enabled: !!(searchParams.get('tab') === 'P3' && get(regionId, 'value'))
    })

    const {data: dispensaryList, isLoading: isLoadingDispensaryList} = useGetAllQuery({
        key: ['dispanseries'],
        url: `${URLS.dashboardReports}/organizations/${get(organization,'value')}`,
        params: {
            params: {
                per_page: 1000
            }
        },
        enabled:  !!(searchParams.get('tab') === 'P3' && get(organization,'value'))
    })

    const {data: reports, isLoading: isLoadingReports} = useGetAllQuery({
        key: KEYS.dashboardReports,
        url: `${URLS.dashboardReports}/organizations/${get(organization,'value')}`,
        params: {params: {organization_ids: dispensary}},
        enabled: !!(searchParams.get('tab') === 'P3' && get(organization,'value'))
    })


    useEffect(() => {
        if (get(dispensaryList, 'data.data') && isArray(get(dispensaryList, 'data.data'))) {
            setDispensary(get(dispensaryList, 'data.data', []).map(({id}) => id))
        }
    }, [dispensaryList]);
    useEffect(() => {
        if (!searchParams.get('organizationId') && get(organizationList, 'data.data') && isArray(get(organizationList, 'data.data'))) {
            setOrganization({value:get(head(get(organizationList, 'data.data',[])),'id'),label:get(head(get(organizationList, 'data.data',[])),'name')})
        }
    }, [organizationList,searchParams.get('regionId')]);
    useEffect(() => {
        if (!searchParams.get('regionId') && get(regionList, 'data.data') && isArray(get(regionList, 'data.data'))) {
            setRegionId({value:get(head(get(regionList, 'data.data',[])),'id'),label:get(head(get(regionList, 'data.data',[])),'name')})
        }
    }, [regionList]);

    useEffect(() => {
        if (!isEmpty(get(regionList, 'data.data', [])) && searchParams.get('regionId')) {
            setRegionId({
                value: get(find(get(regionList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('regionId')), 'id'),
                label: get(find(get(regionList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('regionId')), 'name')
            })
        }
    }, [searchParams.get('regionId'), get(regionList, 'data.data', [])]);

    useEffect(() => {
        if (!isEmpty(get(organizationList, 'data.data', [])) && searchParams.get('organizationId')) {
            setOrganization({
                value: get(find(get(organizationList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('organizationId')), 'id'),
                label: get(find(get(organizationList, 'data.data', []), (_item) => get(_item, 'id') == searchParams.get('organizationId')), 'name')
            })
        }
    }, [searchParams.get('organizationId'), get(organizationList, 'data.data', [])]);

    return (
        <>
            <Section>
                <Title
                    className={'mb-4 !text-2xl'}>{t("Структура прикрепленного населений в разрезе возрастных групп, %")}</Title>
                <div className="grid grid-cols-12">
                    <div className={'col-span-2'}>
                        <Select
                            isClearable={false}
                            value={regionId}
                            setValue={(val) => {
                                setRegionId(val);
                                navigate('?tab=P3&regionId='+get(val,'value'), {replace:true})
                            }}
                            label={t('Region')} options={get(regionList, 'data.data', []).map(item => ({
                            value: get(item, 'id'),
                            label: get(item, 'name')
                        }))}/>
                        <br/>
                        <Select
                            isLoading={isLoadingOrganizationList}
                            isClearable={false}
                            value={organization}
                            setValue={(val) => setOrganization(val)}
                            label={t('Organization')} options={get(organizationList, 'data.data', []).map(item => ({
                            value: get(item, 'id'),
                            label: get(item, 'name')
                        }))}/>
                        <h3 className={'mt-3 font-bold py-1'}>{t("Dispensaries")}</h3>
                        <ul className={'py-2.5 max-h-[55vh] overflow-y-auto pr-1'}>
                            <li className={'mb-2 flex items-center'}>
                                <Checkbox
                                    checked={!isEmpty(dispensary)}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setDispensary(get(dispensaryList, 'data.data', []).map(({id}) => id))
                                        } else {
                                            setDispensary([])
                                        }
                                    }}
                                />
                                <span>{t('All')}</span>
                            </li>
                            {
                                isArray(get(dispensaryList, 'data.data', [])) && get(dispensaryList, 'data.data', []).map((_city) =>
                                    <li key={get(_city, 'id')} className={'mb-2 flex items-center'}><Checkbox
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setDispensary(uniq([...dispensary, get(_city, 'id')]))
                                            } else {
                                                setDispensary(uniq(filterArray(dispensary, (__city) => !isEqual(__city, get(_city, 'id')))))
                                            }
                                        }}
                                        checked={includes(dispensary, get(_city, 'id'))}
                                    />
                                        <span>{get(_city, 'name')}</span>
                                    </li>)
                            }
                        </ul>
                    </div>
                    <div className="col-span-10 h-[675px]">
                        <div className="flex justify-end">
                            <ul className="list-none flex-none mr-5">
                                <li><strong>{t('Жен')}.</strong> <span className={'ml-1.5'}>{sumBy(calculateDashboardChartData(get(reports, 'data.data', []), dispensary),'female').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></li>
                                <li><strong>{t('Муж')}.</strong> <span>{sumBy(calculateDashboardChartData(get(reports, 'data.data', []), dispensary),'male').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></li>
                                <li><strong>{t('Grand Total')}:</strong> <span>{(sumBy(calculateDashboardChartData(get(reports, 'data.data', []), dispensary),'male')+sumBy(calculateDashboardChartData(get(reports, 'data.data', []), dispensary),'female')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></li>
                            </ul>
                            <ChartDatepicker month={month} setMonth={setMonth}/>
                        </div>
                        <BarChart
                            data={calculateDashboardChartData(get(reports, 'data.data', []), dispensary)}/>
                    </div>
                </div>
            </Section>
        </>
    );
};

export default Index;