import React from 'react';

const Content = ({children}) => {
    return (
        <div className={'bg-[#D6D9D5] py-10 pl-16 pr-14  min-h-[calc(100vh-88px)]'}>
            {children}
        </div>
    );
};

export default Content;