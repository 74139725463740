import React from 'react';
import Title from "../../../components/title";
import {useTranslation} from "react-i18next";
import {Tab, Tabs} from "../../../components/tab";
import Section from "../../../components/section";
import {Link} from 'react-router-dom';
import RegionStatistics from "../components/region-statistics";
import OrganizationStatistics from "../components/organization-statistics";
import {useGetAllQuery} from "../../../hooks/api";
import {KEYS} from "../../../constants/keys";
import {URLS} from "../../../constants/urls";
import DispensaryStatistics from "../components/dispensary-statistics";


const DashboardContainer = () => {
    const {t} = useTranslation();
    const {data: regionList, isLoading: isLoadingRegions} = useGetAllQuery({
        key: KEYS.states, url: URLS.states,
    })
    return (
        <div>
            <Tabs>
                <Tab tab={'info'} label={t("Title page")}>
                    <Section>
                        <Title className={'mb-4 !text-2xl'}>{t("Анализ информации медицинских организаций")}</Title>
                        <div>
                            <div>
                                <div
                                    className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>{t("Раздел 1. Прикрепленное население")}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе возрастных групп и пола')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура прикрепленного населения в разрезе организаций ПМСП')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P2
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Структура прикрепленного населений в разрезе возрастных групп, %')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=P3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>P3
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 2. Анализ стационарных госпитализаций (Терапия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H1
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура больничной смертности в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H2
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура госпитализаций по классам МКБ10 в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H3',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H3
                                        </Link>
                                    </li>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Анализ среднего пребывания по выбранной клинической категории')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H4',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H4
                                        </Link>
                                    </li>
                                    <li className={' flex justify-between'}>
                                        <span>{t('Анализ спектра госпитализаций в разрезе классов МКБ10')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=H5',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>H5
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <div className={'bg-[#E5F0F3] py-1 px-2 font-semibold text-lg'}>
                                    {t('Раздел 3. Анализ стационарных госпитализаций (Хирургия)')}
                                </div>
                                <ul className={'py-5'}>
                                    <li className={'mb-2 flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе стационаров')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS1',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS1
                                        </Link>
                                    </li>
                                    <li className={'flex justify-between'}>
                                        <span>{t('Структура хирургических операций в разрезе типов операций по стационарам')}</span>
                                        <Link to={{
                                            pathname: '/',
                                            search: '?tab=HS2',
                                        }}
                                              className={'bg-primary text-white py-2.5 px-5 min-w-[100px] text-center'}>HS2
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Section>
                </Tab>
                <Tab tab={'P1'} label={<div>{t("Прикрепленное население")}
                    <div>P1</div>
                </div>}>
                    <RegionStatistics regionList={regionList}/>
                </Tab>
                <Tab tab={'P2'} label={<div>{t("Прикрепленное население")}
                    <div>P2</div>
                </div>}>
                    <OrganizationStatistics regionList={regionList}/>
                </Tab>
                <Tab tab={'P3'} label={<div>{t("Прикрепленное население")}
                    <div>P3</div>
                </div>}>
                    <DispensaryStatistics regionList={regionList}/>
                </Tab>

            </Tabs>
        </div>
    );
};

export default DashboardContainer;