import React, {lazy, Suspense} from 'react';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import AuthLayout from "../layouts/auth";
import IsAuth from "../services/auth/IsAuth";
import IsGuest from "../services/auth/IsGuest";
import {OverlayLoader} from "../components/loader";
import DashboardLayout from "../layouts/dashboard";
import DashboardPage from "../modules/dashboard/pages/DashboardPage";
import ErrorPage from "../modules/auth/pages/ErrorPage";
import ProfilePage from "../modules/auth/pages/ProfilePage";
import LogoutPage from "../modules/auth/pages/LogoutPage";
import ForbiddenPage from "../modules/auth/pages/ForbiddenPage";
import NotFoundPage from "../modules/auth/pages/NotFoundPage";
import SSOCallbackPage from "../modules/auth/pages/SSOCallbackPage";
import PractitionersPage from "../modules/dispensary/pages/PractitionersPage";
import HasAccess from "../services/auth/HasAccess";
import config from "../config";

const LoginPage = lazy(() => import("../modules/auth/pages/LoginPage"));
const OutPatientsPage = lazy(() => import("../modules/dispensary/pages/OutpatientReportsPage"));
const OutPatientReportsByMonthPage = lazy(() => import("../modules/dispensary/pages/OutpatientReportsByMonthPage"));
const OutPatientReportsByOrganizationsPage = lazy(() => import("../modules/dispensary/pages/OutpatientReportsByOrganiztionsPage"));
const OutPatientReportsByOrganizationPage = lazy(() => import("../modules/dispensary/pages/OutpatientReportsByOrganiztionPage"));
const InPatientsPage = lazy(() => import("../modules/hospitals/pages/InpatientReportsPage"));
const InPatientReportsByMonthPage = lazy(() => import("../modules/hospitals/pages/InpatientReportsByMonthPage"));
const InPatientReportsByOrganizationsPage = lazy(() => import("../modules/hospitals/pages/InpatientReportsByOrganiztionsPage"));
const InPatientReportsByOrganizationsFgmsPage = lazy(() => import("../modules/hospitals/pages/InpatientReportsByOrganiztionsFgmsPage"));
const InPatientReportsByOrganizationsForm066Page = lazy(() => import("../modules/hospitals/pages/InpatientReportsByOrganiztionsForm606Page"));
const InPatientReportsByOrganizationsForm066ViewPage = lazy(() => import("../modules/hospitals/pages/InpatientReportsByForm066Page"));
const BasRatesPage = lazy(() => import("../modules/references/pages/BaseRatesPage"));
const InpatientBasRatesPage = lazy(() => import("../modules/references/pages/InpatientBaseRatesPage"));
const PatientGroupsPage = lazy(() => import("../modules/references/pages/PatientGroupsPage"));
const DrgPage = lazy(() => import("../modules/references/pages/DrgPage"));
const TranslationsPage = lazy(() => import("../modules/settings/pages/TranslationsPage"));
const ReportsPage = lazy(() => import("../modules/report/pages/ReportsPage"));


const Router = ({...rest}) => {
    return (
        <Suspense fallback={<OverlayLoader/>}>
            <BrowserRouter>
                <IsAuth>
                    <Routes>
                        <Route path={"/"} element={<DashboardLayout/>}>
                            <Route index element={<DashboardPage/>}/>
                            <Route path={"dispensaries"}
                                   element={<Navigate to={'/dispensaries/outpatient-reports'} replace/>}/>
                            <Route path={"dispensaries"}>
                                <Route path={'practitioners'} element={<HasAccess can={[config.PERMISSIONS.outpatient_medical_workers]} DeniedComponent={() =><Navigate to={'/403'}/>} ><PractitionersPage /></HasAccess>} />
                                <Route path={'outpatient-reports'}  element={<HasAccess can={[config.PERMISSIONS.outpatient_reports]} DeniedComponent={() =><Navigate to={'/403'}/>} ><OutPatientsPage /></HasAccess>}/>
                                <Route path={'outpatient-reports/:year/:month'}
                                       element={<HasAccess can={[config.PERMISSIONS.outpatient_reports]} DeniedComponent={() =><Navigate to={'/403'}/>} ><OutPatientReportsByMonthPage /></HasAccess>}/>
                                <Route path={'organizations'} element={<HasAccess can={[config.PERMISSIONS.outpatient_organizations]} DeniedComponent={() =><Navigate to={'/403'}/>} ><OutPatientReportsByOrganizationsPage /></HasAccess>} />
                                <Route path={'organizations/:org_id'} element={<HasAccess can={[config.PERMISSIONS.outpatient_organizations]} DeniedComponent={() =><Navigate to={'/403'}/>} ><OutPatientReportsByOrganizationPage /></HasAccess>} />
                                <Route path={'organizations/:year/:month/:state'}
                                       element={<HasAccess can={[config.PERMISSIONS.outpatient_reports]} DeniedComponent={() =><Navigate to={'/403'}/>} ><OutPatientReportsByOrganizationsPage /></HasAccess>} />
                            </Route>
                            <Route path={"hospitals"}
                                   element={<Navigate to={'/hospitals/inpatient-reports'} replace/>}/>
                            <Route path={"hospitals"}>
                                <Route element={<HasAccess can={[config.PERMISSIONS.inpatient_calculations]} DeniedComponent={() =><Navigate to={'/403'}/>} ><InPatientsPage /></HasAccess>} path={'inpatient-reports'} />
                                <Route path={'inpatient-reports/:year/:month'}
                                       element={<InPatientReportsByMonthPage/>}/>
                                <Route path={'organizations'} element={<InPatientReportsByOrganizationsPage/>}/>
                                <Route path={'organizations/form66/:year/:month/:form66_id'} element={<InPatientReportsByOrganizationsForm066ViewPage/>}/>
                                <Route path={'organizations/:year/:month/:state'}
                                       element={<InPatientReportsByOrganizationsPage/>}/>
                                <Route path={'organizations/groups/:year/:month/:group'}
                                       element={<InPatientReportsByOrganizationsFgmsPage/>}/>
                                <Route path={'organizations/groups/form66/:year/:month/:group/:form'}
                                       element={<InPatientReportsByOrganizationsForm066Page/>}/>
                            </Route>
                            <Route path={'references'}>
                                <Route index element={<DrgPage />} />
                                <Route path={"drgs"} element={<HasAccess can={[config.PERMISSIONS.drg_view]} DeniedComponent={() =><Navigate to={'/403'}/>} ><DrgPage /></HasAccess>} />
                                <Route path={"base-rates"} element={<HasAccess can={[config.PERMISSIONS.base_rates_view]} DeniedComponent={() =><Navigate to={'/403'}/>} ><BasRatesPage /></HasAccess>}  />
                                <Route path={"inpatient-base-rates"} element={<HasAccess can={[config.PERMISSIONS.inpatient_base_rates_view]} DeniedComponent={() =><Navigate to={'/403'}/>} ><InpatientBasRatesPage /></HasAccess>} />
                                <Route path={"patient-groups"} element={<HasAccess can={[config.PERMISSIONS.patient_groups_view]} DeniedComponent={() =><Navigate to={'/403'}/>} ><PatientGroupsPage /></HasAccess>} />
                            </Route>
                            <Route path={'settings'}>
                                <Route path={'translations'} element={<HasAccess can={[config.PERMISSIONS.translations_view]} DeniedComponent={() =><Navigate to={'/403'}/>} ><TranslationsPage /></HasAccess>} />
                            </Route>
                            <Route path={'reports'}>
                                <Route index element={<ReportsPage />} />
                            </Route>
                            <Route path={"/error"} element={<ErrorPage/>}/>
                            <Route path={"/profile"} element={<ProfilePage/>}/>
                            <Route path="/auth/logout" element={<LogoutPage/>}/>
                            <Route path={"/403"} element={<ForbiddenPage/>}/>
                            <Route path={"/404"} element={<NotFoundPage/>}/>
                            <Route path={"auth"} element={<Navigate to={'/'} replace/>}/>
                            <Route path={"auth/callback"} element={<Navigate to={'/'} replace/>}/>
                            <Route path={"*"} element={<Navigate to={'404'}/>}/>
                        </Route>
                    </Routes>
                </IsAuth>
                <IsGuest>
                    <Routes>
                        <Route path={"/auth"} element={<AuthLayout/>}>
                            <Route index element={<LoginPage/>}/>
                            <Route path={'callback'} element={<SSOCallbackPage/>}/>
                        </Route>
                        <Route path={"*"} element={<Navigate to={'/auth'} replace/>}/>
                    </Routes>
                </IsGuest>
            </BrowserRouter>
        </Suspense>
    );
};

export default Router;