const config = {
    API_ROOT: process.env.REACT_APP_BASE_URL,
    SSO_ROOT:process.env.REACT_APP_SSO_URL,
    FILE_URL: process.env.REACT_APP_FILE_URL,
    CLIENT_ID:process.env.REACT_APP_CLIENT_ID,
    DEFAULT_APP_LANG: 'ru',
    ROLES: {
        admin:'admin',
        superadmin:'superadmin'
    },
    PERMISSIONS: {
        attached_population:'attached_population',
        therapy:'therapy',
        surgery:'surgery',
        outpatient_calculations:'outpatient_calculations',
        outpatient_organizations:'outpatient_organizations',
        outpatient_medical_workers:'outpatient_medical_workers',
        outpatient_reports:'outpatient_reports',
        outpatient_report_creation:'outpatient_report_creation',
        outpatient_export:'outpatient_export',
        inpatient_calculations:'inpatient_calculations',
        inpatient_organizations:'inpatient_organizations',
        inpatient_treated_cases:'inpatient_treated_cases',
        inpatient_report_creation:'inpatient_report_creation',
        inpatient_export:'inpatient_export',
        drg_view:'drg_view',
        drg_edit:'drg_edit',
        base_rates_view:'base_rates_view',
        base_rates_edit:'base_rates_edit',
        patient_groups_view:'patient_groups_view',
        patient_groups_edit:'patient_groups_edit',
        inpatient_base_rates_view:'inpatient_base_rates_view',
        inpatient_base_rates_edit:'inpatient_base_rates_edit',
        translations_view:'translations_view',
        translations_edit:'translations_edit',
    },
}

export default config;