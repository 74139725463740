import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import HasAccess from "../../services/auth/HasAccess";
import config from "../../config";

const Menu = () => {
    const {t} = useTranslation()
    return (
        <ul className={'flex items-center relative  z-50'}>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/'}>{t('Statistics')}</NavLink>
            </li>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/dispensaries'}>{t("outpatient clinics")}</NavLink>
                <ul className="absolute z-5 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3">
                    <HasAccess can={[config.PERMISSIONS.outpatient_reports]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/dispensaries/outpatient-reports'}>{t("Calculations")}</NavLink>
                        </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.outpatient_organizations]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/dispensaries/organizations'}>{t("Organizations")}</NavLink>
                        </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.outpatient_medical_workers]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/dispensaries/practitioners'}>{t("Medical workers")}</NavLink>
                        </li>
                    </HasAccess>
                </ul>
            </li>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/hospitals'}>{t("hospitals")}</NavLink>
                <ul className="absolute z-5 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3">
                    <HasAccess can={[config.PERMISSIONS.inpatient_calculations]}> <li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/hospitals/inpatient-reports'}>{t("Calculations")}</NavLink>
                    </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.inpatient_organizations]}><li className={'mb-1'}>
                        <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                 to={'/hospitals/organizations'}>{t("Organizations")}</NavLink>
                    </li>
                    </HasAccess>
                    {/*<li className={'mb-1'}>*/}
                    {/*    <Link className={'px-3 py-1 text-black block transition hover:text-primary'}*/}
                    {/*          to={'#'}>{t("Prolonged cases")} </Link>*/}
                    {/*</li>*/}
                </ul>
            </li>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'}
                         to={'/reports'}>{t("Reports")}</NavLink>
            </li>
            <li className={'menu__item group'}>
                <NavLink className={'menu__item_link'} to={'/references'}>{t("references")}</NavLink>
                <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[275px]">
                    <HasAccess can={[config.PERMISSIONS.drg_view]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/references/drgs'}>{t("DRGS")}</NavLink>
                        </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.base_rates_view]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/references/base-rates'}>{t("Base rates")}</NavLink>
                        </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.inpatient_base_rates_view]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/references/inpatient-base-rates'}>{t("Inpatient base rates")}</NavLink>
                        </li>
                    </HasAccess>
                    <HasAccess can={[config.PERMISSIONS.patient_groups_view]}>
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/references/patient-groups'}>{t("Patient groups")}</NavLink>
                        </li>
                    </HasAccess>
                </ul>
            </li>
            <HasAccess can={[config.PERMISSIONS.translations_view]}>
                <li className={'menu__item group'}>
                    <NavLink className={'menu__item_link'} to={'/settings'}>{t("Settings")}</NavLink>
                    <ul className="absolute z-50 bg-white  rounded-b-xl invisible group-hover:visible  -left-6 -right-6 top-2/3 py-3 w-[225px]">
                        <li className={'mb-1'}>
                            <NavLink className={'px-3 py-1 text-black block transition hover:text-primary'}
                                     to={'/settings/translations'}>{t("Translations")}</NavLink>
                        </li>
                    </ul>
                </li>
            </HasAccess>
        </ul>
    );
};

export default Menu;